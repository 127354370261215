import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Services from 'components/Home/Services'
// import CTABanner from 'components/Common/CTABanner'
import CustomBreadCrumb from 'components/Common/Breadcrumb'

// Third Party
import styled from 'styled-components'

const DienstenPage = styled.div``

const DienstenTemplate = ({ data: { home }, pageContext }) => (
  <Layout>
    {/* {() => ( */}
      <>
        <SEO seo={home.seo} />
        <DienstenPage className="position-relative">
          <CustomBreadCrumb
            data={home}
            className="py-2"
            pageContext={pageContext}
          />
          <Services fields={home.pageServices.pageservices} />
          {/* <CTABanner data={home} /> */}
        </DienstenPage>
      </>
    {/* )} */}
  </Layout>
)

export const pageQuery = graphql`
  query ($databaseId: Int!) {
    home: wpPage(databaseId: { eq: $databaseId }) {
      title
      databaseId
      pageServices {
        pageservices {
          title
          service {
            ... on WpPage {
              title
              databaseId
              uri
              recapService {
                icon {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

export default DienstenTemplate
